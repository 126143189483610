
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_12i0j_1tut5_185",
  "drawer-content-container": "awsui_drawer-content-container_12i0j_1tut5_198",
  "drawer-close-button": "awsui_drawer-close-button_12i0j_1tut5_206",
  "drawer-content": "awsui_drawer-content_12i0j_1tut5_198",
  "drawer-content-hidden": "awsui_drawer-content-hidden_12i0j_1tut5_215",
  "drawer-slider": "awsui_drawer-slider_12i0j_1tut5_218"
};
  